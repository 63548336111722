<template>

    <div class="h-auto pb-2">

        <div v-if="name != 'Projects'" class="flex flex-wrap content-start grid grid-cols-3 gap-4 mb-4 mt-auto relative">
            <loader :loading="loading" class="h-full"/>
            <alert-project v-for="(el, index) in search(data.data)" :key="index + el" :alert="el" />
        </div>

        <div v-else class="flex flex-wrap content-start grid grid-cols-3 gap-4 mb-4 mt-auto"
             :class="{'h-32 overflow-hidden': !open, 'h-122 overflow-auto': open}">
            <project v-for="(el, index) in search(data.data)"  :key="'project-' + index" :data="el" />
        </div>

    </div>

</template>

<script>

const AlertProject = () => import('@/components/Desktop/ProjectsManagement/AlertProject.vue');
const Project = () => import('@/components/Desktop/ProjectsManagement/Project.vue')

export default {
    props: ['data', 'name'],
    components: {
        AlertProject,
        Project
    },
    data() {
        return {
            open: false,
        }
    },
    methods: {
        nextPage() { this.$emit('nextPage', this.name) },
        collapse() {
            this.open = false
            this.$emit('collapse', this.name)
        },
        search(data) {
            if(data){
                return data.filter(post => {
                    return post['project_name'].toLowerCase() || post['client_name'].toLowerCase()
                }) 
            } else {
                return []
            }
        },
        
    },
    computed:{
        params() {
            return state.globalParams
        }
    }
}
</script>